<template>
  <v-container class="mt-5 ml-2" fluid v-if="usuariosAcceso.includes( getdatosUsuario.iderp )">
    <v-row>
      <v-col cols="12">
        <v-card class="transparent elevation-0">
          <v-card-title primary-title class="text-subtitle-1">
            Reporte al momento
            <v-spacer></v-spacer>

            <v-btn 
            	color="primary"
            	@click="initialize4()" 
            	small
            	tile
            >
          		Consultar
          	</v-btn>
          </v-card-title>

          <v-card-text>

          	<v-row>
          		<v-col cols="12" md="6">
		            <v-row>
		            	<v-col cols="12" v-if="ciclo" class="text-center">
		            		<span class="text-h4 black--text"> VENTAS POR CICLO </span>
		            		<br/>
		            		<span class="black--text text-h6"> {{ ciclo }} </span>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="6">
		            		<v-card color="shadowCard text-center">
		            		  <v-card-title primary-title>
		            		  	<v-spacer></v-spacer>
		            		    <div>Inscritos Fast: 
		            		    	<v-card color="#BD0014" dark class="pa-4 text-center text-h3 shadowCard" @click="tablaVentasCiclo = tablaFast, dialogVentasCiclo = true ">
		            		    		{{ totalFast }}
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>
		            		</v-card>
		            	</v-col>

		            	<v-col cols="12" md="6" lg="6">
		            		<v-card color="shadowCard">
		            		  <v-card-title primary-title>
		            				<v-spacer></v-spacer>
		            		    <div>Inscritos Inbi: 
		            		    	<v-card color="#006487" dark class="pa-4 text-center text-h3 shadowCard" @click="tablaVentasCiclo = tablaInbi, dialogVentasCiclo = true ">
		            		    		{{ totalInbi }}
		            		    	</v-card>
		            		    </div>
		            		  	<v-spacer></v-spacer>
		            		  </v-card-title>
		            		</v-card>
		            	</v-col>
		            </v-row>
          		</v-col>


          		<v-col cols="12" md="6">
		            <v-row v-if="encabezados">
		              
		          		<v-col cols="12" v-if="ciclo" class="text-center">
		            		<span class="text-h4 black--text"> VENTAS POR SEMANA </span>
		            		<br/>
		            		<span class="black--text text-h6">
			                <div>
			                  <span class="font-weight-bold text-h6">{{ encabezados.fecha_inicio_format }}</span> 
			                  <span class="font-weight-bold mx-4">al</span> 
			                  <span class="font-weight-bold text-h6">{{ encabezados.fecha_final_format }}</span>
			                </div>
		            		</span>
		            	</v-col>

		            	<v-col cols="12" md="12">
		            		<v-card class="shadowCard">
		            		  <v-card-text class="black--text">

		                    <v-data-table
		                      :headers="headers5"
		                      :items="encabezados.matriculas_liquidadasSemAnt > encabezadosFast.matriculas_liquidadasSemAnt ? datosTablaHeaders6 :  datosTablaHeaders5 "
		                      class="elevation-0"
		                      dense
		                      mobile-breakpoint="200"
		                      hide-default-footer
		                      :items-per-page="datosTablaHeaders5.length"
		                    >
		                      <template v-slot:item.columna3="{ item }">
		                        <v-chip small @click="dataTablaSemana = encabezados.matriculas_liquidadasSemAnt > encabezadosFast.matriculas_liquidadasSemAnt ? vendedorasActuales :  vendedorasActualesFAST, tituloSemana = 'Ventas semana', dialogVendedorasSemana = true">{{ item.columna3 }} </v-chip>
		                      </template>
		                    </v-data-table>

		                    <v-data-table
		                      :headers="headers5"
		                      :items="encabezados.matriculas_liquidadasSemAnt < encabezadosFast.matriculas_liquidadasSemAnt ? datosTablaHeaders6 :  datosTablaHeaders5 "
		                      class="elevation-0"
		                      dense
		                      mobile-breakpoint="200"
		                      hide-default-footer
		                      :items-per-page="datosTablaHeaders6.length"
		                    >
		                      <template v-slot:item.columna3="{ item }">
		                        <v-chip small @click="dataTablaSemana = encabezados.matriculas_liquidadasSemAnt < encabezadosFast.matriculas_liquidadasSemAnt ? vendedorasActuales : vendedorasActualesFAST, tituloSemana = 'Ventas semana', dialogVendedorasSemana = true">{{ item.columna3 }} </v-chip>
		                      </template>
		                    </v-data-table>

		            		  </v-card-text>
		            		</v-card>
		            	</v-col>
		            </v-row>

          		</v-col>
          	</v-row>


            <v-row>

              <v-col cols="12" v-if="ciclo" class="text-center">
                <span class="text-h4 black--text"> Seguimiento Reporte NI y RI </span>
              </v-col>

              <v-col cols="12">
                <v-card class="shadowCard">
                  <v-card-text>
                    <v-data-table
                      :headers="headersMiniReporte"
                      :items="miniReporte"
                      class="elevation-0"
                      dense
                    >
                    </v-data-table>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>

            <v-row>

            	<v-col cols="12" v-if="ciclo" class="text-center">
            		<span class="text-h4 black--text"> ESTATUS DE SEGUIMIENTO </span>
            	</v-col>

            	<v-col cols="12" md="6">
            		<v-card class="shadowCard">
						      <v-card-text>
						        <v-data-table
						          :headers="headersCerrados"
						          :items="reporteFast"
						          class="elevation-0"
						          dense
                      :items-per-page="reporteFast.length"
                      hide-default-footer
						        >
						        </v-data-table>
						      </v-card-text>
						    </v-card>
						  </v-col>

						  <v-col cols="12" md="6">
            		<v-card class="shadowCard">
						      <v-card-text >
						        <v-data-table
						          :headers="headersCerrados"
						          :items="reporteInbi"
						          class="elevation-0"
						          dense
                      :items-per-page="reporteInbi.length"
                      hide-default-footer
						        >
						        </v-data-table>
						      </v-card-text>
						    </v-card>
						  </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog
      v-model="dialogAlumnos"
      hide-overlay
      transition="dialog-bottom-transition"
      max-width="1200"
    >
      <v-card>
        <v-card-title primary-title>
          Alumnos
          <v-spacer></v-spacer>

          <v-btn 
            class="mr-2" 
            small dark color="green"
            @click="exportarAlumnos( alumnos , 'ALUMNOS_NI' )"
            v-if="![18,40,19,17,53,52,57].includes( getdatosUsuario.idpuesto )"
          >
            <v-icon>mdi-microsoft-excel</v-icon>
          </v-btn>

        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="dialogAlumnos = false"
            small
            color="green"
            dark
          >
            Cerrar
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <!-- Ventas por ciclo por vendedora -->
    <v-dialog
      v-model="dialogVentasCiclo" 
      max-width="500px"
    >
      <v-card>
      	<v-card-title primary-title class="text-subtitle-1">
      	  Ventas por ciclo por vendedora
      	</v-card-title>
        <v-card-text>
	      	<v-data-table
            :headers="headers"
            :items="tablaVentasCiclo"
            class="elevation-0"
            dense
            mobile-breakpoint="200"
            :items-per-page="tablaVentasCiclo.length"
            hide-default-footer
          >
            <template v-slot:item.completos="{ item }">
              <v-chip
                small
                color="primary"
                @click="abrirDialog( item.completosA )"
              >
                {{ item.completos }}
              </v-chip>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" dark small @click="dialogVentasCiclo = false">
          	Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Ventas por ciclo por vendedora -->
    <v-dialog
      v-model="dialogVendedorasSemana" 
      max-width="500px"
    >
      <v-card>
      	<v-card-title primary-title class="text-subtitle-1">
      	 	{{ tituloSemana }}
      	</v-card-title>
        <v-card-text>
	      	<v-data-table
	          :headers="headers2"
	          :items="dataTablaSemana"
	          class="elevation-0"
	          dense
	          mobile-breakpoint="200"
	          hide-default-footer
	          :items-per-page="dataTablaSemana.length"
	        >
	        </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="black" dark small @click="dialogVendedorasSemana = false">
          	Cancelar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
      
    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  import XLSX from 'xlsx'

  export default {

    components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialogAlumnos:false,

      headers: [
        { text: 'Vendedora'    , value: 'vendedora'  },
        { text: 'I completo'   , value: 'completos'  },
      ],

      headersMiniReporte: [
        { text: 'Escuela'        , value: 'escuela'  },
        { text: 'Monto 1'        , value: 'monto1'  },
        { text: 'Sucursal NI'    , value: 'sucursalni'  },
        { text: 'RI Total'       , value: 'ritotal'  },
        { text: 'RI Sucursal'    , value: 'risucursal'  },
        { text: 'Diferencia Suc' , value: 'dif'  },
      ],

      desserts: [],

      tablaFast:[],
      tablaInbi:[],

      fechaini:null,
      fechafin:null,
      totalInbi:0,
      totalFast:0,

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      headers2: [
        { text: 'Vendedora'       , value: 'nombre_completo'       },
        { text: 'Mat. Nvas. Liq.' , value: 'matriculas_liquidadas' },
      ],

      fechaini:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      nuevasMatriculas:[],
      vendedorasActuales:[],
      encabezados: null,
      encabezados_ant: null,
      escuela: 1,

      headers5: [
        { text: 'Matriculas' , value: 'columna1', width: '40%', align: 'start' },
        { text: 'Semana Ant' , value: 'columna2', width: '35%', align: 'start' },
        { text: 'Semana Act' , value: 'columna3', width: '35%', align: 'start' },
      ],
      datosTablaHeaders5:[],
      datosTablaHeaders6:[],
      vendedorasActualesFAST:[],
      encabezadosFast: null,
			encabezados_antFast: null,

      miniReporte:[],

			reporteFast:[],      
			reporteInbi:[],      

      headersCerrados: [
			  { text: 'Vendedora',    value: 'nombre_completo'   },
        { text: 'Atrasados',    value: 'atrasados'   },
			  { text: 'Nuevos',       value: 'nuevos'      },
			  { text: 'Seguimiento',  value: 'seguimiento' },
			  { text: 'Total',        value: 'total'       },
			  { text: 'Contactos',    value: 'contactos'   },
      ],
      fecha_inicio:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
      fecha_final:(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      ciclo: null,

      tablaVentasCiclo:[],
      dialogVentasCiclo: false,

      dialogVendedorasSemana: false,
			dataTablaSemana:  [],
			tituloSemana: '',

      usuariosAcceso: [334,1,526,361, 568, 718, 859, 255, 966, 568, 7, 161, 3, 32, 718, 1472, 1842, 1778 ]


    }),

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    async created () {
      await this.initialize4()
    },

    methods: {

    	async initialize4(){
    		await this.initialize()
	      await this.initialize2()
        await this.initialize3()
        await this.riSucursales()
	      await this.riSucursalesMonto()

    	},

    	initialize () {
        this.cargar    = true
        var payload = { fechaini: this.fechaini, escuela: this.escuela }

        this.$http.post('kpi.ventasmomento', payload ).then(response=>{

          this.tablaFast        = response.data.vendedorasFast
          this.tablaInbi        = response.data.vendedorasInbi
          this.totalFast        = response.data.totalFast 
          this.totalInbi        = response.data.totalInbi
        	
        	// caoichovnonvavvv
        	this.encabezados            = response.data.encabezados
        	this.encabezados_ant        = response.data.encabezados
        	this.vendedorasActuales     = response.data.vendedorasActuales

        	this.vendedorasActualesFAST = response.data.vendedorasActualesFAST
        	this.encabezadosFast        = response.data.encabezadosFast
        	this.encabezados_antFast    = response.data.encabezadosFast

          this.ciclo                  = response.data.ciclo

          this.datosTablaHeaders5 = [
            { 
              columna1: 'Nvas. Liq. FAST',
              columna2: ` ${ this.encabezadosFast.matriculas_liquidadasSemAnt} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezadosFast.matriculas_liquidadas_montoSemAnt ) }`,
              columna3: ` ${ this.encabezadosFast.matriculas_liquidadas} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezadosFast.matriculas_liquidadas_monto ) }`,
              columna5: this.encabezadosFast.matriculas_liquidadasSemAnt,
              columna6: this.encabezadosFast.matriculas_liquidadas,
            },
          ]

          this.datosTablaHeaders6 = [
            { 
              columna1: 'Nvas. Liq. INBI',
              columna2: ` ${ this.encabezados.matriculas_liquidadasSemAnt} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_liquidadas_montoSemAnt ) }`,
              columna3: ` ${ this.encabezados.matriculas_liquidadas} => ${ new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( this.encabezados.matriculas_liquidadas_monto ) }`,
              columna5: this.encabezados.matriculas_liquidadasSemAnt,
              columna6: this.encabezados.matriculas_liquidadas,
            },
          ]
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      initialize2() {
        // Abrimos modal de carga
        this.cargar = true
        // Limpiamos los prospectos
        this.prospectos  = []

        return this.$http.get('prospectos.vendedora/' + 2 + '/' + this.getdatosUsuario.iderp).then((response) => {
          // Filtramos los que necesitamos, depende si es vendedora o administrador
          this.prospectos = response.data.respuesta

          // Prospectos que se encuentran el inducción o tienen tarea programada que no es para hoy
          this.prospectosAgendadaInduccion  = response.data.prospectosConSeguimiento.concat( response.data.prospectosAgendadaInduccion )

          // Prospectos sin seguimiento hoy y sin tareas programadas
          this.prospectosSeguimiento        = response.data.prospectosSeguimiento

          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      initialize3() {
        const payload = {
        	fecha_inicio: this.fecha_inicio, 
        	fecha_final:  this.fecha_final
        }

        // Mandamos a llamar a la api
        this.$http.post('marketing.contactos',payload).then(response=>{

					for( const i in this.vendedorasActuales ){

						const { nombre_completo, id_usuario } = this.vendedorasActuales[i]

						this.vendedorasActuales[i]['atrasados']   = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa == 1 && el.usuario_asignado == id_usuario }).length
						this.vendedorasActuales[i]['nuevos']      = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa == 2 && el.usuario_asignado == id_usuario }).length
						this.vendedorasActuales[i]['seguimiento'] = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa > 2 && el.usuario_asignado == id_usuario }).length + this.prospectosAgendadaInduccion.filter((el)=>{ return el.idetapa > 2 && el.usuario_asignado == id_usuario }).length
						this.vendedorasActuales[i]['total'] = this.prospectosSeguimiento.filter((el)=>{ return el.usuario_asignado == id_usuario }).length + this.prospectosAgendadaInduccion.filter((el)=>{ return el.idetapa > 2 && el.usuario_asignado == id_usuario }).length

						const existe = response.data.dataTabla.find( el => el.usuario_asignado == id_usuario )
						this.vendedorasActuales[i]['contactos'] = existe ? existe[`${this.fecha_inicio}`] : 0

					}

					for( const i in this.vendedorasActualesFAST ){

						const { nombre_completo, id_usuario } = this.vendedorasActualesFAST[i]

						this.vendedorasActualesFAST[i]['atrasados']   = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa == 1 && el.usuario_asignado == id_usuario }).length
						this.vendedorasActualesFAST[i]['nuevos']      = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa == 2 && el.usuario_asignado == id_usuario }).length
						this.vendedorasActualesFAST[i]['seguimiento'] = this.prospectosSeguimiento.filter((el)=>{ return el.idetapa > 2 && el.usuario_asignado == id_usuario }).length + this.prospectosAgendadaInduccion.filter((el)=>{ return el.idetapa > 2 && el.usuario_asignado == id_usuario }).length
						this.vendedorasActualesFAST[i]['total'] = this.prospectosSeguimiento.filter((el)=>{ return el.usuario_asignado == id_usuario }).length + this.prospectosAgendadaInduccion.filter((el)=>{ return el.idetapa > 2 && el.usuario_asignado == id_usuario }).length

						const existe = response.data.dataTabla.find( el => el.usuario_asignado == id_usuario )
						this.vendedorasActualesFAST[i]['contactos'] = existe ? existe[`${this.fecha_inicio}`] : 0

					}

					this.vendedorasActuales     = [...this.vendedorasActuales].sort((a, b) => b.contactos - a.contactos);
					this.vendedorasActualesFAST = [...this.vendedorasActualesFAST].sort((a, b) => b.contactos - a.contactos);

					this.vendedorasActuales.push({
		        nombre_completo: 'TOTAL',
		        atrasados      : this.vendedorasActuales.map(item => item.atrasados).reduce((prev, curr) => prev + curr, 0),
		        nuevos         : this.vendedorasActuales.map(item => item.nuevos).reduce((prev, curr) => prev + curr, 0),
		        seguimiento    : this.vendedorasActuales.map(item => item.seguimiento).reduce((prev, curr) => prev + curr, 0),
		        total          : this.vendedorasActuales.map(item => item.total).reduce((prev, curr) => prev + curr, 0),
		        contactos      : this.vendedorasActuales.map(item => item.contactos).reduce((prev, curr) => prev + curr, 0),
		        escuela        : 1,
		        matriculas_liquidadas: this.vendedorasActuales.map(item => item.matriculas_liquidadas).reduce((prev, curr) => prev + curr, 0),
		      })

		      this.vendedorasActualesFAST.push({
		        nombre_completo: 'TOTAL',
		        atrasados      : this.vendedorasActualesFAST.map(item => item.atrasados).reduce((prev, curr) => prev + curr, 0),
		        nuevos         : this.vendedorasActualesFAST.map(item => item.nuevos).reduce((prev, curr) => prev + curr, 0),
		        seguimiento    : this.vendedorasActualesFAST.map(item => item.seguimiento).reduce((prev, curr) => prev + curr, 0),
		        total          : this.vendedorasActualesFAST.map(item => item.total).reduce((prev, curr) => prev + curr, 0),
		        contactos      : this.vendedorasActualesFAST.map(item => item.contactos).reduce((prev, curr) => prev + curr, 0),
		        escuela        : 2,
		        matriculas_liquidadas: this.vendedorasActualesFAST.map(item => item.matriculas_liquidadas).reduce((prev, curr) => prev + curr, 0),
		      })

					this.reporteFast = this.vendedorasActualesFAST.filter( el => el.escuela == 2 )
					this.reporteInbi = this.vendedorasActuales.filter( el => el.escuela == 1 )
        	
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      riSucursales () {

        this.miniReporte = []
        
        this.cargar = true
        const payload = {
          cicloPre:  {
            ciclo: "CICLO 10_24",
            fecha_fin_ciclo: "2024-10-07 00:00:00",
            fecha_inicio_ciclo: "2024-11-13 00:00:00",
            id_ciclo: 338,
            id_ciclo_relacionado: 337,
          },
          cicloAct:  {
            ciclo: "CICLO 11_24",
            fecha_fin_ciclo: "2024-12-01 00:00:00",
            fecha_inicio_ciclo: "2024-11-04 00:00:00",
            id_ciclo: 340,
            id_ciclo_relacionado: 339,
          },
          cicloSig:  {
            ciclo: "CICLO 12_24",
            fecha_fin_ciclo: "2025-01-12 00:00:00",
            fecha_inicio_ciclo: "2024-12-02 00:00:00",
            id_ciclo: 342,
            id_ciclo_relacionado: 341,
          },
        }
        return this.$http.post('kpi.cantactual', payload ).then(response=>{

          let fastRI = response.data.riPlantelesFast.find( el => el.plantel == 'TOTAL')
          let inbiRI = response.data.riPlantelesInbi.find( el => el.plantel == 'TOTAL')

          console.log( fastRI, inbiRI )

          let fastMenor = response.data.riPlantelesFast.filter( el => el.reinscribibles > 0 ).sort((a, b) => a.porcentajeAvance - b.porcentajeAvance)

          let inbiMenor = response.data.riPlantelesInbi.filter( el => el.reinscribibles > 0 ).sort((a, b) => a.porcentajeAvance - b.porcentajeAvance)

          this.miniReporte.push({
            escuela: 'FAST',
            ritotal: '%' + fastRI.porcentajeAvance,
            risucursal: fastMenor[0].plantel + ' ( %' + fastMenor[0].porcentajeAvance + ')',
            dif: 0,
            sucursalni:'',
            monto1: this.totalFast + ' de ' + 145 
          })

          this.miniReporte.push({
            escuela: 'INBI',
            ritotal: '%' + inbiRI.porcentajeAvance,
            risucursal: inbiMenor[0].plantel + ' ( %' + inbiMenor[0].porcentajeAvance + ')',
            dif: 0,
            sucursalni:'',
            monto1: this.totalInbi + ' de ' + 401 
          })


        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      riSucursalesMonto () {
        this.cargar = true
        const payload = {
          cicloPre:  {
            ciclo: "CICLO 10_24",
            fecha_fin_ciclo: "2024-10-07 00:00:00",
            fecha_inicio_ciclo: "2024-11-13 00:00:00",
            id_ciclo: 338,
            id_ciclo_relacionado: 337,
          },
          cicloAct:  {
            ciclo: "CICLO 11_24",
            fecha_fin_ciclo: "2024-12-01 00:00:00",
            fecha_inicio_ciclo: "2024-11-04 00:00:00",
            id_ciclo: 340,
            id_ciclo_relacionado: 339,
          },
          cicloSig:  {
            ciclo: "CICLO 12_24",
            fecha_fin_ciclo: "2025-01-12 00:00:00",
            fecha_inicio_ciclo: "2024-12-02 00:00:00",
            id_ciclo: 342,
            id_ciclo_relacionado: 341,
          },
          iderp: 334,
          idpuesto : 11
        }
        return this.$http.post('kpi.cantactual.montos', payload ).then(response=>{

          let fastMenor = response.data.riPlantelesFast.filter( el => el.plantel != 'TOTAL' ).sort((a, b) => a.diferencia - b.diferencia)

          let inbiMenor = response.data.riPlantelesInbi.filter( el => el.plantel != 'TOTAL' ).sort((a, b) => a.diferencia - b.diferencia)

          this.miniReporte[0].dif = fastMenor[0].plantel
          this.miniReporte[1].dif = inbiMenor[0].plantel


          let fastMenor2 = response.data.riPlantelesFast.filter( el => el.plantel != 'TOTAL' ).sort((a, b) => a.niActual - b.niActual)

          let inbiMenor2 = response.data.riPlantelesInbi.filter( el => el.plantel != 'TOTAL' ).sort((a, b) => a.niActual - b.niActual)
          this.miniReporte[0].sucursalni = fastMenor2[0].plantel + ' ( ' + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( fastMenor2[0].niActual ) + ' ) '
          this.miniReporte[1].sucursalni = inbiMenor2[0].plantel + ' ( ' + new Intl.NumberFormat("en-US",{style: "currency", currency: "USD"}).format( inbiMenor2[0].niActual ) + ' ) '


        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirDialog( value ){
        this.alumnos = value
        this.dialogAlumnos = true
      },

      exportar(){
        this.exportarData()
      },

      s2ab2(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      format( valor ){
        return valor.toLocaleString('es-MX', {
          style: 'currency',
          currency: 'MXN'
        });
      }

    },
  }
</script>